import Story from '@/models/Story'

export default class Settings extends Story {
  public get notifications(): boolean {
    return process.env.NODE_ENV === 'production' ? this.content.notifications : true
  }

  public get tickets(): string {
    return this.content?.tickets ?? ''
  }

  public get schedule(): boolean {
    return process.env.NODE_ENV === 'production' ? this.content.schedule : true
  }

  public get map(): boolean {
    return process.env.NODE_ENV === 'production' ? this.content.map : true
  }

  public get showCountry(): boolean {
    return process.env.NODE_ENV === 'production' ? this.content.showCountry : true
  }

  public get showAct(): boolean {
    return process.env.NODE_ENV === 'production' ? this.content.showAct : true
  }

  public get countDown(): string {
    return this.content?.countDown
  }

  public get introImage(): string {
    return this.content?.introImage?.filename
  }

  public get mapImage(): string {
    return this.content?.mapImage?.filename
  }

  public get introImageMobile(): string {
    return this.content?.introImageMobile?.filename
  }

  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram']
      .map((key) => ({
        icon: key,
        link: this.content[key] || null,
      }))
      .filter((i) => i.link)
  }
}
