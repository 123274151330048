import { defineStore } from 'pinia'
import { useStoryblokApi } from '@storyblok/vue'
import { DateTime } from 'ts-luxon'
import { onSnapshot } from 'firebase/firestore'

import Page from '@/models/Page'
import Stage from '@/models/Stage'
import Story from '@/models/Story'
import Artist from '@/models/Artist'
import News from '@/models/News'
import Settings from '@/models/Settings'
import { allDoc } from '@/plugins/firestore'

const hydrateStories = (data: any) => {
  return data.map((story: any) => {
    switch (story.content.component) {
      case 'stage':
        return new Stage(story)
      case 'news':
        return new News(story)
      case 'artist':
        return new Artist(story)
      case 'page':
        return new Page(story)
      case 'settings':
        return new Settings(story)
      default:
        return new Story(story)
    }
  })
}

const blurHash = async (stories: any[], store: any) => {
  return new Promise((resolve, _reject) => {
    const thumbs = stories
      .filter((s) => s.thumb)
      .map((s) => ({
        uuid: s.thumb.split('/')[6],
        url: s.thumb,
        hash: null,
      }))

    // console.log(thumbs)

    onSnapshot(allDoc, (doc) => {
      const data = doc.data()
      const content = JSON.parse(data?.content)
      store.blurhash = content
      // console.log('Hashes loaded...')
      resolve(content)
    })
  })
}

export const useStore = defineStore('store', {
  state: () => ({
    platform: null as string | null,
    splashHidden: false,
    menuOpened: false,
    menuInverted: false,
    modalOpened: 0,
    preventBack: false,
    language: 'sk',

    stories: [] as any[],
    storiesUpdated: null as null | number,

    fcmToken: null as null | string,
    likes: [] as {
      uuid: string
      type?: 'artist' | 'speaker' | 'timeslot'
    }[],
    now: DateTime.now(),
    config: {} as Settings,
    blurhash: [] as {
      uuid: string
      hash: string
      url: string
    }[],
  }),
  getters: {
    getStoryByUuid: (state) => (uuid: string) => {
      return state.stories.find((story: any) => story.uuid === uuid) || null
    },
    getStoryBySlug: (state) => (slug: string) => {
      return state.stories.find((story: any) => story.slug === slug) || null
    },
    getLikeByUuid: (state) => (uuid: string) => {
      return state.likes.find((story: any) => story.uuid === uuid) || null
    },
    getHashByUrl: (state) => (url: string) => {
      return state.blurhash.find((h) => h.url === url) || null
    },
    getHashByUuid: (state) => (uuid: string) => {
      return state.blurhash.find((h) => h.uuid === uuid) || null
    },
  },
  actions: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
      // window.location.hash = this.menuOpened ? '#menu' : ''
    },
    toggleInverted() {
      this.menuInverted = !this.menuInverted
    },
    async loadStories(language = 'sk', force = false) {
      // Poor mans cron
      const now = DateTime.now()
      this.language = language

      if (this.storiesUpdated && !force) {
        const cacheTime = DateTime.fromSeconds(this.storiesUpdated).plus({ minutes: 5 })

        if (now < cacheTime) return
      }

      const perPage = 100
      const storyblokApi = useStoryblokApi()
      const fetchStories = async (page: number) => {
        const { data, total } = await storyblokApi.get('cdn/stories', {
          version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
          per_page: perPage,
          page,
          language: this.language,
        })
        return {
          stories: hydrateStories(data.stories),
          total,
        }
      }

      const getAllStories = async () => {
        let page = 1
        let total = 1
        const stories = []

        while (stories.length < total) {
          const response = await fetchStories(page)

          stories.push(...response.stories)
          total = response.total
          page++

          // console.log(`Loaded ${stories.length} of ${total} stories...`)
        }

        return stories
      }

      this.stories = await getAllStories()

      this.config = this.stories.filter((story: any) => story.type === 'settings')[0] || {}
      this.storiesUpdated = now.toUnixInteger()

      blurHash(this.stories, this)
    },
  },
  persist:
    process.env.NODE_ENV === 'production'
      ? {
          key: 'store',
          afterRestore: (ctx) => {
            ctx.store.now = DateTime.now()
            ctx.store.splashHidden = false
            ctx.store.preventBack = false
            ctx.store.stories = hydrateStories(ctx.store.stories)
            ctx.store.menuOpened = window.location.hash.replace('#', '') === 'menu'
            ctx.store.menuInverted = false
            ctx.store.config =
              ctx.store.stories.filter((story: any) => story.type === 'settings')[0] || {}
          },
        }
      : false,
})
